import router from '@/router';

export function handleResponseActions(actions: string[]): void {
  actions.forEach((action) => {
    switch (action) {
      // Redirecciones
      case responseActionType.REDIRECT_TO_DASHBOARD:
        router.push('/portal');
        break;

      case responseActionType.REDIRECT_TO_ADD_PAYMENT_METHOD:
        router.push('/add-payment-method');
        break;

      case responseActionType.REDIRECT_TO_LOGIN:
        router.push('/login');
        break;

      case responseActionType.REDIRECT_TO_SIGNUP:
        router.push('/sign-up');
        break;
      case responseActionType.REDIRECT_TO_VERIFICATION_SIGNUP:
        router.push('/verification-signup');
        break;
      case responseActionType.REDIRECT_TO_VERIFICATION_LOGIN:
        router.push('/verification-login');
      case responseActionType.REDIRECT_TO_OTP:
        router.push('/otp');
      // Mensajes informativos
      case responseActionType.SHOW_SUCCESS_MESSAGE:
        console.log('Operación completada con éxito.');
        break;

      case responseActionType.SHOW_WELCOME_MESSAGE:
        console.log('¡Bienvenido!');
        break;

      case responseActionType.SHOW_CODE_SENT_MESSAGE:
        console.log('El código OTP se envió correctamente.');
        break;

      case responseActionType.DISPLAY_TRANSACTION_SUCCESS:
        console.log('Transacción completada con éxito.');
        break;

      // Acciones específicas
      case responseActionType.INITIATE_QR_CODE_FLOW:
        console.log('Iniciando flujo de código QR...');
        break;

      case responseActionType.SHOW_QR_CODE:
        console.log('Mostrando el código QR generado.');
        break;

      case responseActionType.WAIT_FOR_PAYMENT_COMPLETION:
        console.log('Esperando la confirmación del pago.');
        break;

      // Errores comunes
      case responseActionType.SHOW_DATABASE_ERROR:
        console.error('Error de base de datos.');
        break;

      case responseActionType.SHOW_VALIDATION_ERROR:
        console.error('Error de validación.');
        break;

      case responseActionType.SHOW_AUTH_ERROR:
        console.error('Error de autenticación.');
        break;
      case responseActionType.SHOW_USER_NOT_FOUND_ERROR:
        console.error('Usuario no encontrado.');
      case responseActionType.SHOW_QR_ERROR:
        console.error('Error al generar el código QR.');

      default:
        console.warn(`Acción no manejada: ${action}`);
        break;
    }
  });
}
// src/config/actionTypes.ts

export enum responseActionType {
  // Actions for general error types
  SHOW_QR_ERROR = 'SHOW_QR_ERROR',
  SHOW_DATABASE_ERROR = 'SHOW_DATABASE_ERROR',
  SHOW_VALIDATION_ERROR = 'SHOW_VALIDATION_ERROR',
  SHOW_AUTH_ERROR = 'SHOW_AUTH_ERROR',
  SHOW_FORBIDDEN_CONTENT_ERROR = 'SHOW_FORBIDDEN_CONTENT_ERROR',
  SHOW_CONTENT_NOT_FOUND_ERROR = 'SHOW_CONTENT_NOT_FOUND_ERROR',
  SHOW_ENTITY_ERROR = 'SHOW_ENTITY_ERROR',
  SHOW_CODE_SENT_MESSAGE = 'SHOW_CODE_SENT_MESSAGE',
  WAIT_FOR_USER_CODE_INPUT = 'WAIT_FOR_USER_CODE_INPUT',

  // Actions for user-specific errors
  SHOW_USER_NOT_FOUND_ERROR = 'SHOW_USER_NOT_FOUND_ERROR',
  SHOW_INVALID_PASSWORD_ERROR = 'SHOW_INVALID_PASSWORD_ERROR',
  SHOW_PHONE_NUMBER_TAKEN_ERROR = 'SHOW_PHONE_NUMBER_TAKEN_ERROR',
  SHOW_INVALID_CODE_ERROR = 'SHOW_INVALID_CODE_ERROR',
  SHOW_CODE_EXPIRED_ERROR = 'SHOW_CODE_EXPIRED_ERROR',
  SHOW_USER_ID_CODE_REQUIRED_ERROR = 'SHOW_USER_ID_CODE_REQUIRED_ERROR',
  SHOW_PHONE_NUMBER_REQUIRED_ERROR = 'SHOW_PHONE_NUMBER_REQUIRED_ERROR',
  SHOW_PHONE_PASSWORD_REQUIRED_ERROR = 'SHOW_PHONE_PASSWORD_REQUIRED_ERROR',
  SHOW_PHONE_PASSWORD_NAME_REQUIRED_ERROR = 'SHOW_PHONE_PASSWORD_NAME_REQUIRED_ERROR',
  SHOW_CREATING_USER_ERROR = 'SHOW_CREATING_USER_ERROR',
  SHOW_PAGINATED_USERS_ERROR = 'SHOW_PAGINATED_USERS_ERROR',
  SHOW_TWILIO_SEND_ERROR = 'SHOW_TWILIO_SEND_ERROR',
  SHOW_UPDATING_USER_ERROR = 'SHOW_UPDATING_USER_ERROR',
  SHOW_NO_TOKEN_PROVIDED_ERROR = 'SHOW_NO_TOKEN_PROVIDED_ERROR',
  SHOW_INVALID_TOKEN_ERROR = 'SHOW_INVALID_TOKEN_ERROR',
  SHOW_INSUFFICIENT_PERMISSIONS_ERROR = 'SHOW_INSUFFICIENT_PERMISSIONS_ERROR',
  SHOW_UNSUPPORTED_SECURITY_NAME_ERROR = 'SHOW_UNSUPPORTED_SECURITY_NAME_ERROR',

  // Actions for Stripe-related errors
  SHOW_PAYMENT_NOT_REQUIRED_ERROR = 'SHOW_PAYMENT_NOT_REQUIRED_ERROR',
  SHOW_PAYMENT_REQUIRES_ACTION = 'SHOW_PAYMENT_REQUIRES_ACTION',
  SHOW_PAYMENT_FAILED_ERROR = 'SHOW_PAYMENT_FAILED_ERROR',
  SHOW_CUSTOMER_NOT_FOUND_ERROR = 'SHOW_CUSTOMER_NOT_FOUND_ERROR',
  SHOW_USER_BLOCKED_ERROR = 'SHOW_USER_BLOCKED_ERROR',
  SHOW_RESEND_NOT_ALLOWED_ERROR = 'SHOW_RESEND_NOT_ALLOWED_ERROR',
  SHOW_SETUP_INTENT_REQUIRES_VERIFICATION = 'SHOW_SETUP_INTENT_REQUIRES_VERIFICATION',
  SHOW_PAYMENT_INTENT_REQUIRES_VERIFICATION = 'SHOW_PAYMENT_INTENT_REQUIRES_VERIFICATION',

  // Actions for success scenarios
  REDIRECT_TO_DASHBOARD = 'REDIRECT_TO_DASHBOARD',
  REDIRECT_TO_ADD_PAYMENT_METHOD = 'REDIRECT_TO_ADD_PAYMENT_METHOD',
  REDIRECT_TO_VERIFICATION_LOGIN = 'REDIRECT_TO_VERIFICATION_LOGIN',
  REDIRECT_TO_VERIFICATION_SIGNUP = 'REDIRECT_TO_VERIFICATION_SIGNUP',
  REDIRECT_TO_OTP = 'REDIRECT_TO_OTP',
  SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE',
  REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN',
  REDIRECT_TO_SIGNUP = 'REDIRECT_TO_SIGNUP',
  SHOW_WELCOME_MESSAGE = 'SHOW_WELCOME_MESSAGE',
  SHOW_PAYMENT_LINK = 'SHOW_PAYMENT_LINK',
  INITIATE_QR_CODE_FLOW = 'INITIATE_QR_CODE_FLOW',
  DISPLAY_TRANSACTION_SUCCESS = 'DISPLAY_TRANSACTION_SUCCESS',

  // Qr actions
  SHOW_QR_CODE = 'SHOW_QR_CODE',
  WAIT_FOR_PAYMENT_COMPLETION = 'WAIT_FOR_PAYMENT_COMPLETION'
}
