<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import {
  IonPage,
  IonContent,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonButton
} from '@ionic/vue';

const router = useRouter();
const { t } = useI18n();

const login = () => {
  router.push('/login');
};

const register = () => {
  router.push('/sign-up');
};
</script>

<template>
  <ion-page>
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row
          class="ion-justify-content-center ion-align-items-center ion-margin-vertical"
          style="height: 40vh"
        >
          <ion-col size="12" class="ion-text-center">
            <ion-card-title style="font-size: 4rem">{{
              t('appTitle')
            }}</ion-card-title>
          </ion-col>
        </ion-row>

        <div style="margin-top: 25vh">
          <ion-button
            class="ion-margin-vertical"
            color="primary"
            shape="round"
            size="large"
            expand="block"
            @click="login"
            >{{ t('loginRegisterPage.login') }}</ion-button
          >
          <ion-button
            class="ion-margin-vertical"
            color="light"
            shape="round"
            size="large"
            expand="block"
            @click="register"
            >{{ t('loginRegisterPage.register') }}</ion-button
          >
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style scoped>
/* No se necesitan estilos adicionales si usas las utilidades de Ionic */
</style>
