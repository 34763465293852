<script setup lang="ts">
import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/vue';
import HeaderHome from '../../components/Header.vue';
import { useI18n } from 'vue-i18n';
import { Icon } from '@iconify/vue';
import CardEarn from '@/components/CardEarn.vue';

const { t } = useI18n();
</script>

<template>
  <ion-page>
    <HeaderHome :showBackButton="true" />
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row class="ion-justify-content-between ion-align-items-center">
          <ion-col>
            <h1>
              {{ t('earnPage.increaseYour') }}
              <span class="monii-color">$Monii</span>
              {{ t('earnPage.balance') }}
            </h1>
          </ion-col>
          <ion-col class="ion-text-right">
            <Icon icon="game-icons:swap-bag" class="header-icon" :ssr="true" />
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <CardEarn
              icon="mdi:cash-multiple"
              :title="t('earnPage.specialOffer')"
              :subtitle="t('earnPage.subtitle')"
              :buttonText="t('earnPage.buttonText')"
            />
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <CardEarn
              icon="mdi:cash-multiple"
              :title="t('earnPage.specialOffer')"
              :subtitle="t('earnPage.subtitle')"
              :buttonText="t('earnPage.buttonText')"
            />
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <CardEarn
              icon="mdi:cash-multiple"
              :title="t('earnPage.specialOffer')"
              :subtitle="t('earnPage.subtitle')"
              :buttonText="t('earnPage.buttonText')"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style scoped lang="scss">
.header-icon {
  color: var(--ion-color-tertiary);
  font-size: 3rem;
}

.monii-color {
  color: var(--ion-color-tertiary);
}
</style>
