<script setup lang="ts">
import {
  IonPage,
  IonGrid,
  IonContent,
  IonCol,
  IonRow,
  IonButton,
  IonCard,
  IonCardContent,
  loadingController
} from '@ionic/vue';
import Header from '@/components/Header.vue';
import { useI18n } from 'vue-i18n';
import { nextTick, onMounted, onUnmounted, Ref, ref } from 'vue';
import * as stripe from '@stripe/stripe-js';
import stripeService from '@/services/stripeService';
import { Icon } from '@iconify/vue';
import PaymentMethodCard from '@/components/PaymentMethodCard.vue';
import { MoniiWallet, updateUserState, userState } from '@/state/userState';
import { useRoute } from 'vue-router';
import router from '@/router';

const route = useRoute();
const stripePromise = stripe.loadStripe(stripeService.stripePublicKey);
const elementsInstance: Ref<stripe.StripeElements | null> = ref(null);
const paymentElement: Ref<stripe.StripePaymentElement | null> = ref(null);

const cardPaymentMethod = ref();
const wallet: Ref<MoniiWallet | null> = ref(null);
const { t } = useI18n();

const { setup_intent, setup_intent_client_secret, redirect_status } =
  route.query;

async function waitForAttachment() {
  if (
    setup_intent &&
    typeof setup_intent === 'string' &&
    setup_intent_client_secret &&
    typeof setup_intent_client_secret === 'string'
  ) {
    const loading = await loadingController.create({
      message: 'Loading...'
    });
    loading.present();
    await stripeService.waitForRegistrationComplete(setup_intent);
    // await loading.dismiss();
    loading.dismiss();
    await nextTick(async () => {
      await router.go(-1);
    });
    return true;
  }
}

onMounted(async () => {
  if (await waitForAttachment()) return;
  const loading = await loadingController.create({
    message: 'Loading...'
  });
  loading.present();
  await mountElements();
  await updateUserState();
  const user = userState.user;
  if (user?.card) {
    cardPaymentMethod.value = user.card;
  }
  wallet.value = user?.wallet ?? null;
  await loading.dismiss();
});

onUnmounted(() => {
  console.log('Unmounted');
});

const handleSubmit = async () => {
  const stripe = await stripePromise;
  const elements = elementsInstance.value;

  if (!stripe || !elements) {
    // Stripe.js hasn't yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    return null;
  }

  const { error } = await stripe.confirmSetup({
    //`Elements` instance that was used to create the Payment Element
    elements,
    confirmParams: {
      return_url: window.location.href
    }
  });

  if (error) {
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Show error to your customer (for example, payment
    // details incomplete)
    console.log(error.message);
  } else {
    // Your customer will be redirected to your `return_url`. For some payment
    // methods like iDEAL, your customer will be redirected to an intermediate
    // site first to authorize the payment, then redirected to the `return_url`.
  }
};

async function mountElements() {
  console.log('Mounting elements');
  const stripeInstance = await stripePromise;

  if (stripeInstance) {
    const setupIntent = await stripeService.createSetupIntent();
    elementsInstance.value = stripeInstance.elements({
      clientSecret: setupIntent.client_secret!,
      appearance: {
        theme: 'flat',
        variables: {}
      }
    });

    paymentElement.value = elementsInstance.value.create('payment', {
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false
      }
    });

    paymentElement.value.mount('#payment-element');

    // Wait for the 'ready' event
    await new Promise((resolve) => {
      paymentElement.value?.on('ready', () => {
        console.log('Payment Element is ready!');
        resolve(1); // Resolve the promise when 'ready' is triggered
      });
    });

    console.log('MountElements function has resolved');
  } else {
    console.log('Stripe instance is null');
  }
}
</script>
<template>
  <ion-page>
    <Header :balance="wallet?.points ?? 0" :showBackButton="false"></Header>
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-row>
              <h4>
                {{ t('walletPage.moniiBalance') }}
              </h4>
              <ion-card class="payment-method-card monii" color="light">
                <ion-card-content class="clear-padding">
                  <ion-grid>
                    <ion-row class="centered-content">
                      <ion-col size="2" class="card-issuer-logo">
                        <Icon
                          icon="game-icons:swap-bag"
                          :ssr="true"
                          width="100%"
                        />
                      </ion-col>
                      <ion-col class="card-data" size="10">
                        <div class="ion-padding-start">
                          <div class="type">
                            {{ wallet?.currency.toUpperCase() ?? '' }}
                          </div>
                          <div class="card-pii">
                            <span>
                              <span class="balance-label"
                                >{{ t('balance') }}:
                              </span>
                              <span class="balance">{{
                                wallet?.points ?? 0
                              }}</span>
                            </span>
                          </div>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-card-content>
              </ion-card>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- Existing payment methods -->
      <ion-grid>
        <ion-row class="ion-padding-top">
          <ion-col>
            <h4>
              {{ t('walletPage.defaultPaymentMethod') }}
            </h4>
            <payment-method-card
              v-if="cardPaymentMethod"
              :network="cardPaymentMethod.network"
              :cardType="cardPaymentMethod.cardType"
              :maskedNumbers="cardPaymentMethod.maskedNumbers"
              :expiry="cardPaymentMethod.expiry"
            ></payment-method-card>
            <ion-card
              class="ion-text-center missing-payment-method"
              color="light"
              v-else
            >
              <ion-card-content class="clear-padding">
                <ion-grid>
                  <ion-row class="centered-content">
                    <ion-col class="card-data" size="12">
                      <div class="ion-padding-start">
                        <div class="type">
                          {{
                            t(
                              'add_payment_method.You_dont_have_a_payment_method'
                            )
                          }}
                        </div>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- Add Payment method -->
      <ion-grid>
        <ion-row>
          <ion-col>
            <h4>
              {{ t('walletPage.changePaymentMethod') }}
            </h4>
            <form id="payment-form" @submit.prevent="handleSubmit">
              <div id="payment-element">
                <!-- Elements will create form elements here -->
              </div>
              <ion-button
                type="submit"
                class="ion-margin-top"
                fill="solid"
                color="primary"
                expand="block"
                id="submit"
              >
                {{ t('walletPage.changePaymentMethod') }}
              </ion-button>
              <div id="error-message">
                <!-- Display error message to your customers here -->
              </div>
            </form>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>
<style scoped lang="scss">
.missing-payment-method {
  color: var(--ion-color-danger);
  border: 1px solid var(--ion-color-danger);
  border-radius: 12px;
}

.centered-content {
  display: flex;
  // justify-content: center;
  align-items: center;
}

.payment-method-card {
  margin-top: 0 !important;
  margin-bottom: 0rem !important;
  border-radius: 12px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &.monii {
    border: 1px solid var(--ion-color-tertiary);
    .card-issuer-logo {
      color: var(--ion-color-tertiary);
    }
    .type {
      color: var(--ion-color-tertiary);
    }
    .balance {
      letter-spacing: 0.1em;
    }
    .balance-label {
      color: var(--ion-color-medium);
    }
  }

  .card-issuer-logo {
    display: flex;
  }
  .card-data {
    .type {
      font-weight: bold;
      font-size: 1.2em;
    }
    .card-pii {
      display: flex;
      justify-content: space-between;
      .card-masked-numbers {
        font-size: 1.1em;
        letter-spacing: 0.1em;
      }
      .card-expiry {
        font-size: 1em;
        color: var(--ion-color-medium);
      }
    }
  }
}
</style>
