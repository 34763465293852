import {
  Configuration,
  AuthApi,
  StripeApi,
  QrApi,
  UsersApi,
  TransactionsApi
} from '@future-planet-for-monkeys/typescript-axios-client';
import axios from 'axios';
import { getBearerToken, getSession } from './sessionHandler';

const axiosInstance = axios.create() as any;

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response: any) => {
    // If the response is successful, just return it
    return response;
  },
  (error: any) => {
    // Check if the error has a response and its status code
    if (error.response) {
      const status = error.response.status;

      // If status is less than 500, resolve the promise with the response
      if (status < 500) {
        return Promise.resolve(error.response);
      }
    }

    // For 500 and above, reject the promise
    return Promise.reject(error);
  }
);

export const authClient = new AuthApi(
  new Configuration({
    basePath: import.meta.env.VITE_API_URL
  }),
  undefined,
  axiosInstance
);

export const stripeClient = new StripeApi(
  new Configuration({
    basePath: import.meta.env.VITE_API_URL,
    apiKey: getBearerToken()
  }),
  undefined,
  axiosInstance
);

export const qrClient = new QrApi(
  new Configuration({
    basePath: import.meta.env.VITE_API_URL,
    apiKey: getBearerToken()
  }),
  undefined,
  axiosInstance
);

export const usersClient = new UsersApi(
  new Configuration({
    basePath: import.meta.env.VITE_API_URL,
    apiKey: getBearerToken()
  }),
  undefined,
  axiosInstance
);

export const transactionsClient = new TransactionsApi(
  new Configuration({
    basePath: import.meta.env.VITE_API_URL,
    apiKey: getBearerToken()
  }),
  undefined,
  axiosInstance
)

