<script setup lang="ts">
import { defineProps, onMounted, ref } from 'vue';
import { Icon } from '@iconify/vue';
import { IonGrid, IonCol, IonRow, IonCard, IonCardContent } from '@ionic/vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

// Define props for the component
const props = defineProps<{
  label?: string | undefined;
  network:
    | 'visa'
    | 'mastercard'
    | 'amex'
    | 'discover'
    | 'diners'
    | 'jcb'
    | 'unionpay'
    | 'maestro'
    | 'mir'
    | 'rupay'
    | 'elo'
    | 'hipercard'
    | 'aura'
    | 'monii'
    | 'unknown';
  cardType: 'debit' | 'credit' | '$Monii';
  maskedNumbers: string;
  expiry: string;
}>();

// Define reactive variable for the icon
const icon = ref<string>('');

// Set the icon based on the network prop
onMounted(() => {
  switch (props.network) {
    case 'visa':
      icon.value = 'ri:visa-line';
      break;
    case 'mastercard':
      icon.value = 'mingcute:mastercard-line';
      break;
    case 'amex':
      icon.value = 'grommet-icons:amex';
      break;
    case 'discover':
      icon.value = 'simple-icons:discover';
      break;
    case 'diners':
      icon.value = 'cib:cc-diners-club';
      break;
    case 'jcb':
      icon.value = 'simple-icons:jcb';
      break;
    case 'unionpay':
      icon.value = 'cib:unionpay';
      break;
    case 'monii':
      icon.value = 'game-icons:swap-bag';
      break;
    default:
      icon.value = 'tdesign:creditcard';
      break;
  }
});
</script>

<template>
  <ion-card class="payment-method-card" color="light">
    <div class="subtitle">
      <div>{{ label }}</div>
    </div>
    <ion-card-content class="clear-padding">
      <ion-grid>
        <ion-row class="centered-content">
          <ion-col size="2" class="card-issuer-logo">
            <Icon :icon="icon" :ssr="true" width="100%" />
          </ion-col>
          <ion-col class="card-data" size="10">
            <div class="ion-padding-start">
              <div class="type">{{ t(cardType, cardType) }}</div>
              <div class="card-pii">
                <span class="card-masked-numbers">{{ maskedNumbers }}</span>
                <span class="card-expiry">{{ expiry }}</span>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<style scoped lang="scss">
div.subtitle {
  float: right;
  position: relative;
  font-size: 0.75em;
  right: 16px;
  top: 8px;
}

.centered-content {
  display: flex;
  align-items: center;
}

.payment-method-card {
  margin: 0;
  border-radius: 12px;

  .card-issuer-logo {
    display: flex;
  }

  .card-data {
    .type {
      font-weight: bold;
      font-size: 1.2em;
      text-transform: capitalize;
    }
    .card-pii {
      display: flex;
      justify-content: space-between;

      .card-masked-numbers {
        font-size: 1.1em;
        letter-spacing: 0.1em;
      }
      .card-expiry {
        font-size: 1em;
        color: var(--ion-color-medium);
      }
    }
  }
}
</style>
