<script setup lang="ts">
import {
  IonPage,
  IonContent,
  IonGrid,
  IonText,
  IonChip,
  IonList,
  IonLabel
} from '@ionic/vue';
import { useI18n } from 'vue-i18n';
import HeaderHome from '../../components/Header.vue';
import PlaceCardTab from '../../components/PlaceCardTab.vue';
import { DateTime } from 'luxon';
import { transactionsClient } from '@/router/axiosClient';
import { onMounted, ref } from 'vue';

export type TabsList = {
  id: string;
  merchantName: string;
  formattedTotal: string;
  itemsCount: number;
  closedAt?: DateTime;
};

const { t } = useI18n();
const tabsList = ref<TabsList[]>([]);

const fetchTabs = () => {
  transactionsClient.listTabs(7, 1, 'es-mx').then((response) => {
    tabsList.value = response.data.map((tab) => ({
      id: tab.id,
      merchantName: tab.merchantName,
      formattedTotal: tab.formattedTotal,
      itemsCount: tab.itemsCount,
      closedAt: tab.closedAt ? DateTime.fromISO(tab.closedAt) : undefined
    }));
  });
};

onMounted(() => {
  fetchTabs();
});
</script>

<template>
  <ion-page>
    <HeaderHome :showBackButton="true" />
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-text>
          <h3>
            <b>{{ t('tabsScreen.tabs') }}</b>
          </h3>
        </ion-text>
        <ion-chip color="success">
          <ion-label>{{ t('tabsScreen.running') }}</ion-label>
        </ion-chip>
        <ion-list :inset="true">
          <PlaceCardTab
            v-for="tab in tabsList.filter((tab) => !tab.closedAt)"
            :key="tab.id"
            :placeData="tab"
          />
        </ion-list>

        <ion-chip color="danger">
          <ion-label>{{ t('tabsScreen.closed') }}</ion-label>
        </ion-chip>

        <ion-list :inset="true">
          <PlaceCardTab
            v-for="tab in tabsList.filter((tab) => tab.closedAt)"
            :key="tab.id"
            :placeData="tab"
          />
        </ion-list>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style lang="scss" scoped>
ion-list {
  background-color: transparent !important;
}
</style>
