<script setup lang="ts">
import { TabsList } from '@/views/tabs/TabsScreen.vue';
import { IonText, IonItem, IonAvatar, IonLabel, IonCol } from '@ionic/vue';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const router = useRouter();
const { t, locale } = useI18n();
const imageSrc = 'https://via.placeholder.com/150';

const props = defineProps<{
  placeData: TabsList;
}>();

const formatDateToText = (date: DateTime) => {
  return date.setLocale(locale.value).toLocaleString(DateTime.DATE_FULL);
};

const navigateToTabPayScreen = () => {
  router.push('/tab-pay/' + props.placeData.id);
};
</script>

<template>
  <ion-item
    :button="true"
    lines="none"
    :detail="true"
    class="custom-item"
    @click="navigateToTabPayScreen"
  >
    <ion-avatar slot="start">
      <img :src="imageSrc" alt="Place Image" />
    </ion-avatar>
    <ion-label class="ion-justify-content-around">
      <h3>{{ placeData.merchantName }}</h3>
      <ion-text color="medium">
        <p>{{ t('placeCardTab.items') }}: {{ placeData.itemsCount }}</p>
        <p>
          {{ placeData.closedAt ? formatDateToText(placeData.closedAt) : '' }}
        </p>
      </ion-text>
    </ion-label>
    <ion-col size="auto">
      <h4>
        <ion-text>{{ placeData.formattedTotal }}</ion-text>
      </h4>
    </ion-col>
  </ion-item>
</template>

<style lang="scss" scoped>
.custom-item {
  border: 2px solid var(--ion-color-light);
  border-radius: 8px;
  margin-bottom: 10px;
}
</style>
