<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import {
  IonIcon,
  IonPage,
  IonContent,
  IonGrid,
  IonButton,
  IonCol,
  IonRow
} from '@ionic/vue';
import HeaderHome from '../components/Header.vue';
import ImageCard from '@/components/ImageCard.vue';
import PayQrModal from '../components/PayQrModal.vue';
import TransactionCard from '@/components/TransactionCard.vue';
import { DateTime } from 'luxon';
import { useRouter } from 'vue-router';
import { transactionsClient } from '@/router/axiosClient';
import { LineItemTransaction } from '@/components/TransactionCard.vue';

const { t } = useI18n();
const router = useRouter();

const showHistoryButton = ref(true);
const isModalOpen = ref(false);
const transactions = ref<LineItemTransaction[]>([]);

const openModal = () => {
  isModalOpen.value = true;
};

const setHistoryTransactions = () => {
  transactionsClient
    .listLineItemTransactions(5, 1, 'es-mx')
    .then((response) => {
      console.log(response.data);
      transactions.value = response.data.map((txn) => ({
        id: txn.id,
        cartId: txn.cartId,
        rewardsDescription: txn.rewardsDescription,
        merchantName: txn.merchantName,
        transactionDate: DateTime.fromISO(txn.transactionDate),
        formattedPrice: txn.formattedPrice,
        rewardPoints: txn.rewardPoints,
        promotion: txn.specialOffer
      }));
    });
};

const closeModal = () => {
  isModalOpen.value = false;
};

const navigateToEarn = () => {
  router.push('/earn');
};

const navigateToRedeem = () => {
  router.push('/redeem');
};

const toggleButton = () => {
  router.push('/tabs');
};

onMounted(() => {
  setHistoryTransactions();
});
</script>

<template>
  <ion-page>
    <!-- {{ JSON.stringify(session.user) }} -->
    <HeaderHome />
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1>{{ t('homePage.welcome', { name: 'Carlos' }) }}</h1>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4">
            <ImageCard
              icon-source="streamline:payment-cash-out-3"
              :content="t('homePage.pay')"
              @click="openModal"
            />
          </ion-col>
          <ion-col size="4">
            <ImageCard
              icon-source="ri:token-swap-line"
              :content="t('homePage.redeem')"
              @click="navigateToRedeem"
            />
          </ion-col>
          <ion-col size="4">
            <ImageCard
              icon-source="clarity:coin-bag-line"
              :content="t('homePage.earn')"
              @click="navigateToEarn"
            />
          </ion-col>
        </ion-row>

        <h5>{{ t('homePage.lastTransactions') }}</h5>
        <TransactionCard
          v-for="txn in transactions"
          :key="txn.id"
          :points-txn="txn"
        />

        <div class="bottom-button">
          <ion-button color="light" expand="block" @click="toggleButton">
            {{ t('homePage.tabHistory') }}
          </ion-button>
        </div>
      </ion-grid>
    </ion-content>
    <PayQrModal
      :isOpen="isModalOpen"
      @didDismiss="() => (isModalOpen = false)"
    />
  </ion-page>
</template>

<style scoped>
.card-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 7px;
}

.card-container > * {
  flex: 1;
  margin: 0px;
}

.text-chip-spacing {
  margin-bottom: 2rem;
}

.bottom-button {
  margin-top: 4rem;
}
</style>
