import { stripeClient } from '@/router/axiosClient';
import { TMoniiAPIResponseBoolean } from '@future-planet-for-monkeys/typescript-axios-client';
import * as Stripe from '@stripe/stripe-js';
import { AxiosResponse } from 'axios';

class StripeService {
  constructor() {}
  public stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY ?? '';
  public async createSetupIntent(): Promise<Stripe.SetupIntent> {
    try {
      const response = await stripeClient.createSetupIntent();
      const { success, failure } = response.data;
      if (!success) {
        throw failure;
      }
      return success as Stripe.SetupIntent;
    } catch (error) {
      throw error;
    }
  }

  public async waitForRegistrationComplete(
    setupintentId: string
  ): Promise<void> {
    const MAX_ATTEMPTS = 5;
    try {
      let response: TMoniiAPIResponseBoolean;
      let attempts = 0;
      do {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        response = await stripeClient
          .getRegisterPaymentMethodStatus({
            setup_intent: setupintentId
          })
          .then((r) => r.data);
        console.log('response', response);
      } while (!response.success && attempts++ < MAX_ATTEMPTS);
    } catch (error) {
      throw error;
    }
  }
}

export default new StripeService();
