import { IUserDto } from '@future-planet-for-monkeys/typescript-axios-client';
import { exportJWK, generateKeyPair } from 'jose';
import { jwtDecode, JwtPayload } from 'jwt-decode';
const PRE_VERIFICATION_EXPIRATION_MS = 1000 * 60 * 2; // 5 minutes
export const OTP_RATE_LIMIT_TIME_FALLBACK = 10 * 1000 * 60; // 1 minute

export type SessionState = {
  user: IUserDto | null;
  jwt: string | undefined;
  otpSentAt: number | null;
  verified: boolean;
  verificationExpiresAt: number | null;
  otpRateLimitedUntil: number | null;
};

const SESSION_KEY = 'user_session';
const JWT_KEY = 'jwt_token';

export function clearSessionData() {
  localStorage.removeItem(SESSION_KEY);
  localStorage.removeItem(JWT_KEY);
}

export function isGuest(): boolean {
  return !localStorage.getItem(JWT_KEY);
}

export function logout(): void {
  clearSessionData();
  // Redirect to home page after logout.
  // we use location instead of router to clear the state of the app
  location.replace('/');
}

export function getSession(): SessionState | undefined {
  const session = localStorage.getItem(SESSION_KEY);
  if (session) {
    try {
      return JSON.parse(session) as SessionState;
    } catch {
      console.error('Invalid session format.');
      return undefined;
    }
  }
  return undefined;
}

export function getBearerToken(): string | undefined {
  const token = localStorage.getItem(JWT_KEY);
  return token ? 'Bearer ' + token : undefined;
}

/**
 * Updates the current session state with the provided partial session data.
 * If a current session exists, it merges the current session state with the
 * partial session data and stores the updated session in local storage.
 *
 * @param partialSession - An object containing partial session state to update.
 */
export function updateSession(partialSession: Partial<SessionState>): void {
  const currentSession = getSession();
  if (currentSession) {
    localStorage.setItem(
      SESSION_KEY,
      JSON.stringify({ ...currentSession, ...partialSession })
    );
  }
}

/**
 * Logs in a user by decoding the provided JWT, storing it in local storage,
 * and initializing a session state with placeholder user information.
 *
 * @param {string} jwt - The JSON Web Token to be decoded and stored.
 * @returns {Promise<void>} A promise that resolves when the login process is complete.
 * @throws Will throw an error if the JWT decoding or storage fails.
 */
export async function login(jwt: string): Promise<void> {
  try {
    const decoded = jwtDecode(jwt);
    const session = {} as SessionState;
    // TODO: Replace with actual user info
    session.user = decoded as IUserDto & JwtPayload;
    session.verified = session.user.role === 'authenticated';
    session.verificationExpiresAt = Date.now() + PRE_VERIFICATION_EXPIRATION_MS;
    session.otpRateLimitedUntil = 0;
    session.jwt = jwt;
    localStorage.setItem(JWT_KEY, jwt);
    localStorage.setItem(SESSION_KEY, JSON.stringify(session));
  } catch (error) {
    console.error('Login failed:', error);
    // Clear token if login fails
    clearSessionData();
    throw error;
  }
}

export async function generateAndStoreSigKeyPair() {
  // Generate an ECDSA P-256 key pair (ES256)
  const { privateKey, publicKey } = await generateKeyPair('ES256', {
    extractable: true,
    modulusLength: 2048
  });

  const privateJwk = await exportJWK(privateKey);
  const publicJwk = await exportJWK(publicKey);

  privateJwk.use = 'sig';
  privateJwk.alg = 'ES256';

  publicJwk.use = 'sig';
  publicJwk.alg = 'ES256';

  // Store the private key
  localStorage.setItem('privateKey', JSON.stringify(privateJwk));
  // Possibly store the public key too
  localStorage.setItem('publicKey', JSON.stringify(publicJwk));

  console.log('Private JWK:', privateJwk);
  console.log('Public JWK:', publicJwk);

  return { privateJwk, publicJwk };
}

export async function signMessage(message: string) {
  const privateKeyJSON = localStorage.getItem('privateKey');

  if (!privateKeyJSON) {
    throw new Error('Failed to sign message');
  }

  const privateJwk = JSON.parse(privateKeyJSON); // Retrieve private key JWK from localStorage

  const importedPrivateKey = (await crypto.subtle.importKey(
    'jwk',
    privateJwk,
    { name: 'ECDSA', namedCurve: 'P-256' },
    true,
    ['sign']
  )) as CryptoKey;

  // Encode the message to Uint8Array
  const encoder = new TextEncoder();
  const data = encoder.encode(message);

  // Use the Web Crypto API to sign the raw data.
  // The keys returned by jose are CryptoKeys compatible with subtle crypto.
  const signatureBuffer = await crypto.subtle.sign(
    { name: 'ECDSA', hash: 'SHA-256' },
    importedPrivateKey,
    data
  );

  // Convert the raw signature (ArrayBuffer) to a base64url-encoded string
  const signatureUint8 = new Uint8Array(signatureBuffer);
  const signatureBase64 = btoa(String.fromCharCode(...signatureUint8))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  return { signatureBase64, message };
}

export async function getPaymentMessage(paymentMethodId: string) {
  const exp = Date.now() + 60 * 1000; // 1 minute
  const message = `${paymentMethodId},${exp}`;
  const payload = await signMessage(message);
  return `${payload.message}.${payload.signatureBase64}`;
}
