<script setup lang="ts">
import { IonHeader, IonToolbar, IonTitle, IonProgressBar } from '@ionic/vue';
import { useI18n } from 'vue-i18n';
// Props
defineProps<{
  progress: string;
}>();
const { t } = useI18n();
</script>

<template>
  <ion-header>
    <ion-toolbar>
      <div class="header-wrapper">
        <ion-title
          router-link="/"
          router-direction="back"
          style="cursor: pointer"
        >
          {{ t('appTitle') }}
        </ion-title>
      </div>
    </ion-toolbar>
    <ion-progress-bar :value="+progress" type="determinate"></ion-progress-bar>
  </ion-header>
</template>
