<script setup lang="ts">
import { IonGrid, IonRow, IonCol } from '@ionic/vue';
import { DateTime } from 'luxon';
import { PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t, locale } = useI18n();
const router = useRouter();

export type LineItemTransaction = {
  id: string;
  cartId: string;
  rewardsDescription: string;
  merchantName: string;
  transactionDate: DateTime;
  formattedPrice: string;
  rewardPoints: number;
  promotion: boolean;
};

const transactionData = defineProps({
  pointsTxn: {
    type: Object as PropType<LineItemTransaction>,
    required: true
  }
});

const formatDate = (date: DateTime) => {
  return date.setLocale(locale.value).toLocaleString(DateTime.DATE_SHORT);
};

const navigateToTabPayScreen = () => {
  router.push({
    path: '/tab-pay/' + transactionData.pointsTxn.cartId
  });
};
</script>

<template>
  <ion-row
    id="points"
    style="cursor: pointer"
    :class="{ promotion: transactionData.pointsTxn.promotion }"
    @click="navigateToTabPayScreen"
  >
    <ion-col size="3" id="jumbo-points-amount">
      <h1>+{{ transactionData.pointsTxn.rewardPoints }}</h1>
      <span> {{ t('transactionCard.points') }} </span>
    </ion-col>
    <ion-col size="7" class="column-space-around little-padding-right">
      <ion-row id="placename">
        {{ transactionData.pointsTxn.merchantName }}
      </ion-row>
      <ion-row id="message">
        {{ transactionData.pointsTxn.rewardsDescription }}
      </ion-row>
    </ion-col>
    <ion-col size="2" class="column-space-around right">
      <ion-row id="cost" class="ion-text-medium">
        {{ transactionData.pointsTxn.formattedPrice }}
      </ion-row>
      <ion-row id="date">
        {{ formatDate(transactionData.pointsTxn.transactionDate) }}
      </ion-row>
    </ion-col>
  </ion-row>
</template>

<style scoped lang="scss">
.promotion {
  &#points {
    box-shadow: 0px 0px 0px 2px var(--ion-color-tertiary) inset !important;
    #placename {
      color: var(--ion-color-tertiary);
    }
    #jumbo-points-amount {
      & * {
        color: var(--ion-color-tertiary);
      }
    }
    #message,
    #date {
      color: var(--ion-color-dark);
      font-size: 0.85rem;
    }
    #cost {
      font-size: 0.85rem;
      color: var(--ion-color-medium);
    }
  }
}

#points {
  padding: 0.45rem;
  border-radius: 1rem;
  background-color: var(--ion-color-light);

  margin: 0.6rem 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  #message,
  #date {
    font-size: 0.85rem;
    color: var(--ion-color-medium);
  }
  #cost {
    font-size: 0.85rem;
    color: var(--ion-color-medium);
  }

  #jumbo-points-amount {
    & * {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h1 {
      padding: 0;
      margin: 0;
      font-size: 2rem;
      font-weight: 700;
    }
    span {
      margin-left: 0.75rem;
      margin-top: 0;
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}

.column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
