<script setup lang="ts">
import { IonButton, IonIcon, IonText } from '@ionic/vue';
import { ref } from 'vue';
import Thumbnail from '../assets/Thumbnail.svg';
import { star } from 'ionicons/icons';

// Definir el objeto JSON con los datos
const placeData = ref({
  imageSrc: Thumbnail,
  name: 'Bar gema',
  rating: 4.5,
  location: '123 Main St, City',
  hours: ['9:00 AM', '11:00 PM']
});
</script>

<template>
  <ion-button fill="clear" class="custom-button" expand="block">
    <div class="button-content">
      <div class="left-section">
        <img
          :src="placeData.imageSrc"
          alt="Place Image"
          class="circular-image"
        />
      </div>
      <div class="middle-section">
        <div class="name-rating">
          <ion-text color="dark"
            ><h4>{{ placeData.name }}</h4></ion-text
          >
          <ion-icon :icon="star"></ion-icon>
          <ion-text color="dark"
            ><h4>{{ placeData.rating }}</h4></ion-text
          >
        </div>
        <div class="location">
          <ion-text>{{ placeData.location }}</ion-text>
        </div>
      </div>
      <div class="right-section">
        <ion-text
          color="dark"
          v-for="(hour, index) in placeData.hours"
          :key="index"
          >{{ hour }}</ion-text
        >
      </div>
    </div>
  </ion-button>
</template>

<style scoped>
.custom-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-bottom: 1px solid #ccc;
}

.button-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.left-section {
  flex: 0 0 auto;
}

.middle-section {
  flex: 1;
  padding: 0 10px;
}

.right-section {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}

.circular-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.name-rating {
  display: flex;
  align-items: center;
  align-items: flex-end;
}

.name-rating h3 {
  margin: 0;
  margin-right: 5px;
}

.name-rating h4 {
  margin: 0;
  margin-right: 5px;
}

.name-rating ion-icon {
  margin-right: 5px;
}

.location {
  margin-top: 5px;
  margin-right: 5px;
  font-size: 0.9rem;
  color: gray;
  text-align: left;
}
</style>
