import { jwtDecode } from 'jwt-decode';
import { SessionState, getSession } from '../sessionHandler';

export function isExpiredSession(session: SessionState): boolean {
  try {
    const jwt = jwtDecode(session.jwt ?? '');
    const exp = jwt.exp ?? 0;
    const currentTime = Math.floor(Date.now() / 1000);
    console.log(
      `Current time: ${currentTime}, JWT exp: ${exp}, timeleft: ${exp - currentTime}s`
    );
    return exp < currentTime;
  } catch (e) {
    console.log(`Malformed token Error: ${e}`);
    return false;
  }
}

export function isUnverifiedSession(session: SessionState): boolean {
  return !session.verified;
}

export function sessionOrFalse(): false | SessionState {
  const session = getSession();
  if (!session) {
    console.log('No session found');
    return false;
  }
  return session;
}
