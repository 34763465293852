<script lang="ts" setup>
import { CardPaymentMethod, updateUserState } from '@/state/userState';
import QRCodeStyling from 'qr-code-styling';
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonRow,
  IonGrid,
  IonCol,
  alertController
} from '@ionic/vue';
import { uuid4 } from '@sentry/utils';
import { onUnmounted, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import PaymentMethodCard from '@/components/PaymentMethodCard.vue';
import { loadingController } from '@ionic/vue';
import { userState } from '@/state/userState';
import { getPaymentMessage } from '@/router/sessionHandler';
const payQRImgSrcData = ref<string | null>(null);
const cardData: Ref<null | CardPaymentMethod> = ref(null);
const refresh = ref<boolean>(true);
const loopStarted = ref<boolean>(false);
const loaded = ref<boolean>(false);
// add props here
const props = defineProps<{
  isOpen: boolean;
}>();

// Define the modal ref
const modal = ref();

const { t } = useI18n();

async function presentAlert() {
  const alert = await alertController.create({
    header: 'Payment Method Required',
    message: 'Please add a payment method to pay.',
    backdropDismiss: false,
    buttons: [
      {
        text: 'Add Payment Method',
        role: 'confirm',
        handler: () => {
          modal.value.$router.push('/add-payment-method');
          dismiss();
        }
      }
    ]
  });

  await alert.present();
}

async function didPresent() {
  const loading = await loadingController.create({
    message: 'Loading...'
  });
  loading.present();
  loaded.value = false;
  await updateUserState();
  const user = userState.user;
  if (!user?.card) {
    loading.dismiss();
    await presentAlert();
  } else {
    // todo: get the user's payment method
    payQRImgSrcData.value = user?.id ?? uuid4();
    cardData.value = user.card;
    startQRCodeRefresh();
    loaded.value = true;
    loading.dismiss();
  }
}

async function startQRCodeRefresh() {
  if (loopStarted.value) {
    // this
    return;
  }
  refresh.value = true;
  loopStarted.value = true;
  const user = userState.user;
  while (refresh.value) {
    const message = await getPaymentMessage(user?.card?.paymentMethodId!);
    refreshQrCode(message);
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }
}

async function stopQRCodeRefresh() {
  refresh.value = false;
  loopStarted.value = false;
}

function didDismiss() {
  console.log('stopQRCodeRefresh');
  loaded.value = false;

  stopQRCodeRefresh();
}

function refreshQrCode(data: string) {
  const qrCode = new QRCodeStyling({
    type: 'svg',
    data: data,
    dotsOptions: {
      color: 'black',
      type: 'square'
    },
    backgroundOptions: {
      color: 'white'
    }
  });
  const el = document.getElementById('qr-container');
  el!.innerHTML = '';
  qrCode.append(el as any);
  el?.querySelector('svg')?.setAttribute('width', '100%');
  el?.querySelector('svg')?.setAttribute('height', '');
}

function dismiss() {
  if (modal.value.$el) {
    modal.value.$el.dismiss();
  }
}

onUnmounted(async () => {
  stopQRCodeRefresh();
});
</script>

<template>
  <ion-modal
    ref="modal"
    :initial-breakpoint="1"
    :breakpoints="[0, 1]"
    :is-open="props.isOpen"
    @didPresent="didPresent"
    @didDismiss="didDismiss"
  >
    <ion-header>
      <ion-toolbar>
        <ion-title>Pay</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">{{ t('close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content
      class="ion-padding"
      :style="{ display: loaded ? 'unset' : 'none' }"
    >
      <ion-grid v-show="cardData">
        <ion-row class="ion-justify-content-center">
          <!-- <h1>
            {{ t('scan_to_pay') }}
          </h1> -->
        </ion-row>
        <ion-row
          class="ion-justify-content-center ion-margin-bottom ion-margin-top"
        >
          <div id="qr-container"></div>
        </ion-row>
        <ion-row>
          <ion-col>
            <payment-method-card
              v-if="cardData"
              @click="dismiss()"
              :label="t('active_payment_method')"
              router-link="/add-payment-method"
              style="cursor: pointer"
              :network="(cardData?.network as any) ?? 'unknown'"
              :cardType="(cardData?.cardType as any) ?? ''"
              :maskedNumbers="cardData?.maskedNumbers ?? ''"
              :expiry="cardData?.expiry ?? ''"
            ></payment-method-card>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <payment-method-card
              network="monii"
              cardType="$Monii"
              :maskedNumbers="`${t('balance')}: ${userState.user?.wallet?.points ?? 0}`"
              expiry=""
            ></payment-method-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-modal>
</template>

<style scoped lang="scss">
#qr-container {
  width: 75%;
}

h1 {
  color: var(--ion-text-color, #000);
}
</style>
