<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { IonSelect, IonSelectOption, IonIcon } from '@ionic/vue';
import { locationOutline, notificationsOutline } from 'ionicons/icons';

const { t } = useI18n();

const selectedLocation = ref(null);

const locations = [
  { value: 'location1', label: 'Location 1' },
  { value: 'location2', label: 'Location 2' },
  { value: 'location3', label: 'Location 3' }
];
</script>

<template>
  <div class="location-select-container">
    <ion-icon :icon="locationOutline" class="location-icon"></ion-icon>
    <ion-select
      v-model="selectedLocation"
      placeholder="Select a location"
      interface="action-sheet"
    >
      <ion-select-option
        v-for="location in locations"
        :key="location.value"
        :value="location.value"
      >
        {{ location.label }}
      </ion-select-option>
    </ion-select>
  </div>
</template>

<style scoped>
.location-select-container {
  display: flex;
  align-items: center;
}

.location-icon,
.notification-icon {
  font-size: 1.5rem;
  margin: 0 0.5rem;
}

.notification-icon {
  margin-left: auto;
}
</style>
