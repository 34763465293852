<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { authClient } from '@/router/axiosClient';
import countries from '@/assets/countries.json';
import { phone } from 'phone';
import '../../styles.css';

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonProgressBar,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption
} from '@ionic/vue';
import { handleResponseActions } from '@/router/handleActions';
import { login } from '@/router/sessionHandler';
import router from '@/router';
import PreLoginHeader from '@/components/PreLoginHeader.vue';

const { t } = useI18n();
const mobileNumber = ref('');
const errorMessages = ref('');
const selectedCountry = ref('');
const isKeyboardOpen = ref(false);

const fullNumber = () => `${selectedCountry.value}${mobileNumber.value}`;
const phoneFormatted = () => phone(fullNumber());

const validatePhoneNumber = () => {
  return phoneFormatted().isValid;
};

const phoneNumberTouched = () => {
  return mobileNumber.value.length > 0;
};

const doLogin = async () => {
  if (!validatePhoneNumber()) return;
  errorMessages.value = '';
  const fullPhoneNumber = phoneFormatted().phoneNumber!;
  try {
    const response = await authClient.login({
      phoneNumber: fullPhoneNumber
    });
    const { success, actions = [], failure } = response.data;
    if (success) {
      const token = success;
      login(token);
      router.push('/otp');
    } else {
      errorMessages.value = failure?.message || t('SignUpPage.loginFailed');
    }

    handleResponseActions(actions);
  } catch (error) {
    const err = error as any;
    const { failure, actions = [] } = err.response?.data || {};
    errorMessages.value = failure || t('SignUpPage.loginFailed');
    console.error('Login failed:', err.response?.data || err.message);
    handleResponseActions(actions);
  }
};

const handleFocus = () => {
  console.log('Keyboard open');
  isKeyboardOpen.value = true;
};

onMounted(() => {
  const defaultCountry = countries.find((country) => country.code === 'MX');
  if (defaultCountry) {
    selectedCountry.value = defaultCountry.extension;
  }
});

const canSubmit = () => {
  return validatePhoneNumber();
};
</script>

<template>
  <ion-page>
    <PreLoginHeader
      :title="t('appTitle')"
      progress=".33"
      routerLink="/"
      direction="back"
    />
    <ion-content class="ion-padding">
      <ion-grid class="full-height">
        <form @submit.prevent="doLogin">
          <!-- Form title -->
          <ion-row class="ion-margin-button">
            <ion-col size="12">
              <ion-text>
                <h3>
                  <b>{{ t('loginPage.welcomeBack') }}</b>
                </h3>
              </ion-text>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="4">
              <ion-select
                mode="ios"
                :class="{
                  'ion-touched': phoneNumberTouched(),
                  'ion-invalid': !validatePhoneNumber(),
                  'ion-valid has-focus': validatePhoneNumber()
                }"
                interface="action-sheet"
                fill="solid"
                v-model="selectedCountry"
                class="country-select"
              >
                <ion-select-option
                  v-for="country in countries"
                  :key="country.code"
                  :value="country.extension"
                >
                  {{ country.flag }} {{ country.extension }}
                </ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col size="8">
              <ion-input
                mode="md"
                :class="{
                  'ion-touched': phoneNumberTouched(),
                  'ion-invalid': !validatePhoneNumber(),
                  'ion-valid has-focus': validatePhoneNumber()
                }"
                fill="solid"
                type="tel"
                :label="t('SignUpPage.mobileNumber')"
                label-placement="floating"
                inputmode="numeric"
                v-model="mobileNumber"
                @ionInput="validatePhoneNumber"
                @ionFocus="handleFocus"
              ></ion-input>
              <!-- Error message -->
              <ion-text color="danger" v-if="errorMessages">
                {{ errorMessages }}
              </ion-text>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col size="12" class="ion-align-items-center">
              <!-- Continue button -->
              <ion-button
                :disabled="!canSubmit()"
                class="ion-margin-top"
                color="primary"
                shape="round"
                size="default"
                expand="block"
                type="submit"
                >{{ t('loginPage.logIn') }}</ion-button
              >
            </ion-col>
          </ion-row>

          <!-- Login redirect -->
          <ion-row class="ion-justify-content-center ion-align-items-center">
            <ion-text>
              <p style="text-align: center">
                {{ t('loginPage.dontHaveAccount') }}
                <RouterLink to="/sign-up">
                  <b>{{ t('loginPage.registerNow') }}</b>
                </RouterLink>
              </p>
            </ion-text>
          </ion-row>
        </form>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style lang="scss">
.full-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.button-container {
  margin-top: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.keyboard-open.button-container {
  margin-top: 5px;
}

.action-sheet-open {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 250px;
}

.required {
  color: var(--ion-text-color-step-600);
  font-size: 0.75rem;
}

.margin {
  margin-top: 50px;
  margin-bottom: 80px;
}
</style>
