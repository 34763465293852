<script setup lang="ts">
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { Icon } from '@iconify/vue';
import { IonText } from '@ionic/vue';
const props = defineProps({
  iconSource: {
    type: String,
    required: true
  },
  content: {
    type: String,
    required: true
  },
  redirectTo: {
    type: String,
    required: false
  }
});

const router = useRouter();

const handleClick = () => {
  if (props.redirectTo) {
    router.push(props.redirectTo);
  }
};
</script>

<template>
  <button class="square-button">
    <div>
      <h1 class="icon">
        <Icon :icon="iconSource" :ssr="true" />
      </h1>
    </div>
    <div>
      <ion-text>{{ content }}</ion-text>
    </div>
  </button>
</template>

<style lang="scss">
.square-button {
  // padding: em !important;
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Align content vertically */
  align-items: center; /* Center child elements horizontally */
  justify-content: center; /* Center child elements vertically */
  width: 100%; /* Fill parent's width */
  height: 100%; /* Fill parent's height */
  aspect-ratio: 1; /* Make it a square */
  // border: 0.1rem solid var(--ion-text-color); /* Remove default border */
  border-radius: 1em;
  padding: 0; /* Remove default padding */
  box-sizing: border-box; /* Include padding and border in size */
  cursor: pointer; /* Add pointer cursor for interactivity */

  @media (prefers-color-scheme: dark) {
    background-color: var(--ion-text-color);
    color: var(--ion-background-color);
  }
  @media (prefers-color-scheme: light) {
    background-color: var(--ion-color-light);
    color: var(--ion-text-color);
  }
  & {
    box-shadow: 0px 0px 0px 0.5px var(--ion-color-medium) inset !important;
  }

  h1 {
    font-size: 2rem; /* Adjust icon size */
  }
  div {
    display: flex; /* Ensure children are aligned inside divs */
    justify-content: center;
    align-items: center;
  }

  ion-text {
    font-size: 1rem; /* Adjust text size */
  }
}
</style>
