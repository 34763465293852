import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Ionic Dark Mode */
import '@ionic/vue/css/palettes/dark.system.css';

/* Theme variables */
import './theme/variables.css';

import en from '../locales/en.json';
import es from '../locales/es.json';
import 'flag-icon-css/css/flag-icons.css';

const deviceLanguage = navigator.language.split('-')[0];

const i18n = createI18n({
  locale: deviceLanguage,
  fallbackLocale: 'en',
  messages: {
    en,
    es
  }
});

/* Create Vue app */
const app = createApp(App).use(IonicVue).use(router).use(i18n);
const date = Date.now();

// new Promise(async (resolve) => {
//   while (1) {
//     await new Promise((_resolve) => {
//       setTimeout(() => _resolve(1), 1000);
//     });
//     console.log('Date:', date);
//   }
// });

router.isReady().then(() => {
  app.mount('#app');
});
