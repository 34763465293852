<script setup lang="ts">
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonText
} from '@ionic/vue';
import HeaderHome from '../../components/Header.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <ion-page>
    <HeaderHome :showBackButton="true" />
    <ion-content class="ion-padding">
      <ion-grid
        class="full-height ion-justify-content-center ion-align-items-center"
      >
        <ion-row>
          <ion-col class="ion-text-center">
            <ion-text>
              <h1>{{ t('redeemPage.comingSoon') }}</h1>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style scoped lang="scss">
.full-height {
  height: 100vh;
}
</style>
