import { createRouter, createWebHistory } from '@ionic/vue-router';
import { NavigationGuard, RouteRecordRaw } from 'vue-router';
import LoginRegisterPage from '@/views/LoginRegisterPage.vue';
import LoginPage from '@/views/login/LoginPage.vue';
import OTP from '@/views/otp/OtpPage.vue';
import SignUpPage from '@/views/signup/SignUpPage.vue';
import walletPage from '@/views/wallet/WalletPage.vue';
import HomeScreenPage from '@/views/HomeScreenPage.vue';
import SearchScreen from '@/views/SearchScreen.vue';
import TabsScreen from '@/views/tabs/TabsScreen.vue';
import NfcIdScreen from '@/views/nfc_id/NfcIdPage.vue';
import ActivateIdPage from '@/views/nfc_id/ActivateIdPage.vue';
import NewTagPage from '@/views/nfc_id/NewTagPage.vue';
import ProfilePage from '@/views/ProfilePage.vue';
import {
  mustBeLoggedInUserGuard,
  mustNotHaveSessionGuard,
  mustBeUnexpiredSessionGuard,
  mustBeUnverifiedSessionGuard,
  mustBeVerifiedSessionGuard
} from './middleware/guards';
import { redirectToPortalOrLanding } from './middleware/redirects';
import TabPayScreen from '@/views/tabs/TabPayScreen.vue';
import TabsPage from '@/views/TabsPage.vue';
import EarnPage from '@/views/earn/EarnPage.vue';
import RedeemPage from '@/views/redeem/RedeemPage.vue';

function guardRoutes(guards: NavigationGuard[], routes: RouteRecordRaw[]) {
  return routes.map((route) => {
    const routeOwnGuards = route.beforeEnter
      ? Array.isArray(route.beforeEnter)
        ? route.beforeEnter
        : [route.beforeEnter]
      : [];
    return { ...route, beforeEnter: [...routeOwnGuards, ...(guards || [])] };
  });
}

const guestRoutes = guardRoutes(
  [],
  [
    {
      path: '/otp',
      component: OTP,
      beforeEnter: [mustBeUnexpiredSessionGuard, mustBeUnverifiedSessionGuard]
    },
    {
      path: '/landing',
      component: LoginRegisterPage,
      beforeEnter: [mustNotHaveSessionGuard]
    },
    {
      path: '/',
      redirect: redirectToPortalOrLanding
    },
    {
      path: '/login',
      component: LoginPage,
      beforeEnter: [mustNotHaveSessionGuard]
    },
    {
      path: '/sign-up',
      component: SignUpPage,
      beforeEnter: [mustNotHaveSessionGuard]
    }
  ]
);

const protectedRoutes = guardRoutes(
  [mustBeLoggedInUserGuard, mustBeVerifiedSessionGuard],
  [
    {
      path: '/portal',
      component: HomeScreenPage
    },
    {
      path: '/add-payment-method',
      component: walletPage
    },
    {
      path: '/search-screen',
      component: SearchScreen
    },
    {
      path: '/tabs',
      component: TabsScreen
    },
    {
      path: '/tab-pay/:cartId',
      component: TabPayScreen,
      props: true,
    },
    {
      path: '/earn',
      component: EarnPage
    },
    {
      path: '/redeem',
      component: RedeemPage
    },
    {
      path: '/profile-page',
      component: ProfilePage
    },
    {
      path: '/nfc-id',
      component: NfcIdScreen,
      children: [
        {
          path: '',
          redirect: '/nfc-id/show-qr'
        },
        {
          path: 'new-tag',
          component: NewTagPage
        },
        {
          path: 'show-qr',
          component: ActivateIdPage
        }
      ]
    }
  ]
);

const routes = [
  ...guestRoutes,
  ...protectedRoutes,
  { path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

export default router;
