<script setup lang="ts">
import { useRouter } from 'vue-router';
import {
  IonPage,
  IonContent,
  IonGrid,
  IonText,
  IonChip,
  IonButton,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel
} from '@ionic/vue';
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import HeaderHome from '../../components/Header.vue';
import PaymentMethodCard from '@/components/PaymentMethodCard.vue';
import { transactionsClient } from '@/router/axiosClient';
import { DateTime } from 'luxon';
import { FormattedPaymentMethodCardNetwork } from '@future-planet-for-monkeys/typescript-axios-client';

export type LiteItem = {
  description: string;
  formattedPrice: string;
};

export type Totals = {
  formattedSubtotal: string;
  formattedTax: string;
  formattedTotal: string;
};

export type Card = {
  network: string;
  cardType: string;
  maskedNumbers: string;
  expiry: string;
};

export type PaymentMethod = {
  card: {
    network: FormattedPaymentMethodCardNetwork;
    cardType: 'debit' | 'credit' | '$Monii';
    maskedNumbers: string;
    expiry: string;
  };
};

export type TabDetails = {
  liteItems: LiteItem[];
  totals: Totals;
  rewards: number;
  paymentMethods: PaymentMethod[];
  closedAt?: DateTime;
  merchantName: string;
};

const { t, locale } = useI18n();
const router = useRouter();
const cartId = router.currentRoute.value.params.cartId as string;
const tabDetails = ref<TabDetails | null>(null);

const fetchTabDetails = () => {
  transactionsClient.getTab(cartId, 'es-mx').then((response) => {
    tabDetails.value = {
      liteItems: response.data.liteItems,
      totals: response.data.totals,
      rewards: response.data.rewards,
      paymentMethods: response.data.paymentMethods,
      closedAt: response.data.closedAt
        ? DateTime.fromISO(response.data.closedAt)
        : undefined,
      merchantName: response.data.merchantName
    };
  });
};

const formatDateToText = (date: DateTime) => {
  return date.setLocale(locale.value).toLocaleString(DateTime.DATE_FULL);
};

const payTab = () => {
  // Lógica para pagar la cuenta
  console.log('Pay Tab clicked');
};

onMounted(() => {
  fetchTabDetails();
});
</script>

<template>
  <ion-page>
    <HeaderHome :showBackButton="true" />
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row class="ion-justify-content-between ion-align-items-center">
          <ion-col>
            <ion-text>
              <h3>
                <b>{{ tabDetails?.merchantName }}</b>
              </h3>
            </ion-text>
          </ion-col>
          <ion-col class="ion-text-right">
            <ion-text>
              <h5>
                {{
                  tabDetails?.closedAt
                    ? formatDateToText(tabDetails.closedAt)
                    : ''
                }}
              </h5>
            </ion-text>
          </ion-col>
        </ion-row>

        <ion-row class="ion-margin-top">
          <ion-col>
            <ion-text>
              <h4>{{ t('tabPayScreen.payment') }}</h4>
            </ion-text>
            <div
              v-if="tabDetails?.paymentMethods.length"
              class="ion-margin-top"
            >
              <payment-method-card
                v-for="(method, index) in tabDetails.paymentMethods"
                :key="index"
                router-link="/add-payment-method"
                style="cursor: pointer"
                :network="
                  method.card.network as
                    | 'visa'
                    | 'mastercard'
                    | 'amex'
                    | 'discover'
                    | 'diners'
                    | 'jcb'
                    | 'unionpay'
                    | 'maestro'
                    | 'mir'
                    | 'rupay'
                    | 'elo'
                    | 'hipercard'
                    | 'aura'
                    | 'monii'
                    | 'unknown'
                "
                :cardType="method.card.cardType"
                :maskedNumbers="'**** **** **** ' + method.card.maskedNumbers"
                :expiry="method.card.expiry"
              ></payment-method-card>
            </div>
          </ion-col>
        </ion-row>

        <!-- Order Details -->
        <ion-row class="ion-margin-top">
          <ion-col>
            <ion-text>
              <h4>{{ t('tabPayScreen.orderDetails') }}</h4>
            </ion-text>
            <ion-list :inset="true">
              <ion-item
                v-for="(item, index) in tabDetails?.liteItems"
                :key="index"
              >
                <ion-label>{{ item.description }}</ion-label>
                <ion-text slot="end">{{ item.formattedPrice }}</ion-text>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

        <!-- Totals -->
        <ion-row class="ion-margin-top">
          <ion-col>
            <ion-list :inset="true">
              <ion-item>
                <ion-label>{{ t('tabPayScreen.subtotal') }}</ion-label>
                <ion-text slot="end">{{
                  tabDetails?.totals.formattedSubtotal
                }}</ion-text>
              </ion-item>
              <ion-item>
                <ion-label>{{ t('tabPayScreen.taxes') }}</ion-label>
                <ion-text slot="end">{{
                  tabDetails?.totals.formattedTax
                }}</ion-text>
              </ion-item>
              <ion-item>
                <ion-label
                  ><b>{{ t('tabPayScreen.total') }}</b></ion-label
                >
                <ion-text slot="end"
                  ><b>{{ tabDetails?.totals.formattedTotal }}</b></ion-text
                >
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row class="ion-margin-top">
          <ion-col>
            <ion-button
              expand="block"
              color="primary"
              shape="round"
              @click="payTab"
              :disabled="!!tabDetails?.closedAt"
            >
              {{
                tabDetails?.closedAt
                  ? t('tabPayScreen.tabPaid')
                  : t('tabPayScreen.payTab')
              }}
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row class="ion-margin-top ion-justify-content-center">
          <ion-chip color="success">
            <ion-label
              >{{ t('tabPayScreen.youEarned') }} {{ tabDetails?.rewards }}
              {{ t('tabPayScreen.credits') }}</ion-label
            >
          </ion-chip>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style scoped>
.star-icon {
  font-size: 2rem;
  color: var(--ion-color-primary);
  cursor: pointer;
  margin-right: 0.5rem;
}
</style>
