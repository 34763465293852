<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonButton
} from '@ionic/vue';
import { Icon } from '@iconify/vue';
import { onMounted } from 'vue';
import { updateUserState, userState } from '@/state/userState';

// i18n and router setup
const { t } = useI18n();
const router = useRouter();
// Props
const props = defineProps({
  showBackButton: {
    type: Boolean,
    default: false
  }
});

// Methods
const goBack = () => {
  router.back();
};

onMounted(async () => {
  await updateUserState();
});
</script>

<template>
  <ion-header>
    <ion-toolbar>
      <ion-title class="app-title" router-link="/">
        {{ t('appTitle') }}
      </ion-title>
      <ion-buttons slot="start" v-if="props.showBackButton">
        <ion-back-button @click="goBack"></ion-back-button>
      </ion-buttons>
      <ion-buttons
        slot="primary"
        class="add-payment-method"
        router-link="/add-payment-method"
      >
        <div class="wallet-info">
          <Icon
            icon="game-icons:swap-bag"
            class="wallet-icon"
            :ssr="true"
            width="1.5rem"
          />
          <span class="wallet-points">
            {{ userState.user?.wallet?.points ?? 0 }}
          </span>
        </div>
        <ion-button class="profile-button" router-link="profile-page">
          <Icon icon="qlementine-icons:user-16" :ssr="true" width="1.7em" />
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<style scoped>
.app-title {
  cursor: pointer;
}

.add-payment-method {
  cursor: pointer;
}

.wallet-info {
  display: flex;
  align-items: flex-end;
  margin: 0 1rem;
  padding: 0.1rem;
}

.wallet-icon {
  color: var(--ion-color-tertiary);
}

.wallet-points {
  letter-spacing: 0.1rem;
  font-size: 0.75rem;
}

.profile-button * {
  color: var(--ion-toolbar-color, var(--ion-text-color, #424242)) !important;
}
</style>
