<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonProgressBar,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonText
} from '@ionic/vue';
import {
  generateAndStoreSigKeyPair,
  getBearerToken,
  getSession,
  login,
  logout,
  OTP_RATE_LIMIT_TIME_FALLBACK,
  SessionState,
  updateSession
} from '@/router/sessionHandler';
import { authClient } from '@/router/axiosClient';

import humanizeDuration from 'humanize-duration';
// Define the router instance
const router = useRouter();
const { t } = useI18n();
const errorMessages = ref('');
const session = ref<SessionState | null>(getSession() ?? null);
// Define the reactive variables
const isKeyboardOpen = ref<boolean>(false);
const reloading = ref(setInterval(() => {}, 1000));
const otp = ref<number>();
const phoneNumber = ref<string>(session?.value?.user?.phoneNumber ?? '');
const until = getSession()!.otpRateLimitedUntil! - Date.now();

const timeUntilNextResend = ref<number>(until < 0 ? 0 : until);
const canVerify = () => {
  return (otp.value?.toString().length ?? 0) > 5;
};

const doLogout = () => {
  logout();
  router.push('/');
};
function replaceResendInterval() {
  clearInterval(reloading.value);
  reloading.value = setInterval(() => {
    const timeUntil = getSession()!.otpRateLimitedUntil! - Date.now();
    timeUntilNextResend.value = timeUntil < 0 ? 0 : timeUntil;
  }, 1000);
}

async function sendVerificationCode() {
  const otpRateLimitedUntil = session!.value!.otpRateLimitedUntil!;
  if (otpRateLimitedUntil < Date.now()) {
    // verification code expired allow user to resend
    try {
      const result = await authClient.sendVerificationCode({
        phoneNumber: phoneNumber.value
      });
      const { success, failure } = result.data;
      if (success) {
        updateSession({
          otpRateLimitedUntil: new Date(Date.now() + success).getTime()
        });
      } else if (failure) {
        if (failure.name === 'otp_rate_limited') {
          errorMessages.value = t('verificationPage.otpRateLimited');
          const retryAfter =
            (failure?.metadata ?? {})['retryAfter'] ??
            OTP_RATE_LIMIT_TIME_FALLBACK;
          updateSession({
            otpRateLimitedUntil: new Date(Date.now() + retryAfter).getTime()
          });
        } else {
          logout();
          return;
        }
      }
      return;
    } catch (e) {
      console.log(e);
    }
  }
  console.log(`Verification code not expired current
  time: ${Date.now()} expires at: ${otpRateLimitedUntil} time left: ${otpRateLimitedUntil - Date.now()}`);
}

async function verify() {
  console.log('Verifying...');
  const result = await authClient.login({
    phoneNumber: phoneNumber.value,
    otp: (otp.value && otp.value.toString()) || undefined
  });
  const { success: jwt, failure } = result.data;
  if (jwt) {
    await login(jwt);
    const { publicJwk } = await generateAndStoreSigKeyPair();
    await authClient.setPublicKey(
      {
        publicKey: JSON.stringify(publicJwk)
      },
      {
        headers: {
          Authorization: getBearerToken()
        }
      }
    );
    location.replace('/portal');
  } else {
    otp.value = undefined;
    errorMessages.value = t('verificationPage.invalidOtp');
  }
}

onBeforeMount(async () => {
  replaceResendInterval();
  await sendVerificationCode();
});
</script>
<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ t('appTitle') }}</ion-title>
      </ion-toolbar>
      <ion-progress-bar :value="0.66" type="determinate"></ion-progress-bar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-grid>
        <form @submit.prevent="verify">
          <ion-row>
            <ion-col class="ion-text-center">
              <ion-text>
                <h1>
                  <b>{{ t('verificationPage.verifyPhone') }}</b>
                </h1>
              </ion-text>
              <ion-text color="medium">
                <p>
                  {{
                    t('verificationPage.enterOtp', { phoneNumber: phoneNumber })
                  }}
                </p>
              </ion-text>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col>
              <ion-input
                fill="solid"
                mode="md"
                type="tel"
                :maxlength="6"
                v-model="otp"
              ></ion-input>
            </ion-col>
          </ion-row>

          <ion-row
            v-if="errorMessages && errorMessages.length > 0"
            class="ion-text-center"
          >
            <ion-col>
              <ion-text color="danger">
                {{ errorMessages }}
              </ion-text>
            </ion-col>
          </ion-row>

          <ion-row class="ion-text-center ion-margin-top">
            <ion-col>
              <ion-text color="medium">
                <div>
                  {{ t('verificationPage.dontHaveReceiveCode') }}
                </div>
                <div>
                  <a
                    href="javascript:void(0)"
                    :style="{
                      'pointer-events':
                        timeUntilNextResend != 0 ? 'none' : 'auto'
                    }"
                    :aria-disabled="timeUntilNextResend != 0"
                    @click="timeUntilNextResend === 0 && sendVerificationCode()"
                  >
                    <b>{{ t('verificationPage.resend') }}</b>
                  </a>
                  {{
                    timeUntilNextResend === 0
                      ? ''
                      : t('in') +
                        humanizeDuration(timeUntilNextResend, {
                          largest: 2,
                          round: true,
                          units: ['m', 's']
                        })
                  }}
                </div>
              </ion-text>
            </ion-col>
          </ion-row>

          <ion-row class="ion-margin-top">
            <ion-col>
              <ion-button
                :disabled="!canVerify()"
                color="primary"
                shape="round"
                size="default"
                expand="block"
                type="submit"
                >{{ t('verificationPage.verify') }}</ion-button
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-button
                color="light"
                shape="round"
                size="default"
                expand="block"
                @click="doLogout"
                >{{ t('verificationPage.logout') }}</ion-button
              >
            </ion-col>
          </ion-row>
        </form>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style lang="scss"></style>
