<script setup lang="ts">
import { ref } from 'vue';
import { IonSearchbar } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const searchQuery = ref('');
const handleSearch = () => {
  console.log('Search query:', searchQuery.value);
};
</script>

<template>
  <div class="search-container">
    <ion-searchbar
      v-model="searchQuery"
      :placeholder="t('search')"
      class="search-input"
    ></ion-searchbar>
  </div>
</template>

<style scoped>
.search-container {
  display: flex;
  align-items: center;
  width: 100%;
}
</style>
