<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import countries from '@/assets/countries.json';
import { phone } from 'phone';

import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonCheckbox
} from '@ionic/vue';
import { authClient } from '@/router/axiosClient';
import { handleResponseActions } from '@/router/handleActions';
import { login } from '@/router/sessionHandler';
import PreLoginHeader from '@/components/PreLoginHeader.vue';
const { t } = useI18n();
const fullName = ref('');
const mobileNumber = ref('');
const errorMessages = ref('');
const birthdate = ref('');
const selectedCountry = ref('');
const isKeyboardOpen = ref(false);
const acceptTerms = ref(false);
const acceptPromotions = ref(false);
const fullNumber = () => `${selectedCountry.value}${mobileNumber.value}`;
const phoneFormatted = () => phone(fullNumber());
const validatePhoneNumber = () => {
  return phoneFormatted().isValid;
};

const isOver18 = () => {
  const birthdateValue = new Date(birthdate.value);
  // Check for invalid date
  const birthTime = birthdateValue.getTime();
  if (isNaN(birthTime) || birthTime < 0) {
    return false;
  }

  const now = new Date();

  // Calculate the difference in years
  let age = now.getFullYear() - birthdateValue.getFullYear();

  // Adjust age if the birthdate hasn't occurred yet this year
  const hasHadBirthdayThisYear =
    now.getMonth() > birthdateValue.getMonth() ||
    (now.getMonth() === birthdateValue.getMonth() &&
      now.getDate() >= birthdateValue.getDate());

  if (!hasHadBirthdayThisYear) {
    age--;
  }

  if (age < 18) {
    return false;
  }

  return true;
};

const validateFullName = () => {
  return fullName.value.length >= 5;
};

const canSubmit = () => {
  return (
    validateFullName() &&
    validatePhoneNumber() &&
    isOver18() &&
    acceptTerms.value
  );
};

const register = async () => {
  if (!canSubmit()) {
    return;
  }
  try {
    errorMessages.value = '';
    if (!phoneFormatted().isValid) {
      errorMessages.value = t('SignUpPage.invalidPhoneNumber');
      return;
    }
    const data = {
      name: fullName.value,
      phoneNumber: phoneFormatted().phoneNumber!, // Número con extensión de país
      birthday: birthdate.value,
      subscribedToNewsletter: acceptPromotions.value
    };
    const response = await authClient.signUp(data);
    const { success: token, actions = [], failure } = response.data;
    if (token) {
      login(token);
      location.replace('/');
    } else if (failure) {
      errorMessages.value = t(failure.name);
      if (failure.name === 'phone_number_taken') {
        mobileNumber.value = '';
      }
    }
    handleResponseActions(actions);
  } catch (error) {
    const err = error as any;

    console.error('Register failed:', err.response?.data.failure || err);
    errorMessages.value = err.response?.data.failure || 'Error inesperado';
    handleResponseActions(err.response?.data.actions || []);
  }
};

const handleFocus = () => {
  console.log('Keyboard open');
  isKeyboardOpen.value = true;
};

const handleBlur = () => {
  console.log('Keyboard closed');
  isKeyboardOpen.value = false;
};

onMounted(() => {
  const defaultCountry = countries.find((country) => country.code === 'MX');
  if (defaultCountry) {
    selectedCountry.value = defaultCountry.extension;
  }
});
</script>

<template>
  <ion-page>
    <PreLoginHeader
      :title="t('appTitle')"
      progress=".33"
      routerLink="/"
      direction="back"
    />
    <ion-content class="ion-padding">
      <ion-grid class="full-height">
        <form @submit.prevent="register">
          <!-- Form title -->
          <ion-row>
            <ion-col size="12">
              <ion-text>
                <h3>
                  <b>{{ t('SignUpPage.createAccount') }}</b>
                </h3>
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-text color="medium">
                <small>{{ '* ' + t('required') }}</small>
              </ion-text>
            </ion-col>
          </ion-row>

          <!-- Personal information -->

          <!-- Full name -->
          <ion-row>
            <ion-col>
              <ion-input
                mode="md"
                :class="{
                  'ion-touched': fullName.length > 0,
                  'ion-invalid': !validateFullName(),
                  'ion-valid has-focus': validateFullName()
                }"
                fill="solid"
                ref="input"
                type="text"
                :label="t('SignUpPage.fullName') + ' *'"
                label-placement="floating"
                v-model="fullName"
                @ionFocus="handleFocus"
                @ionBlur="handleBlur"
              ></ion-input>
            </ion-col>
          </ion-row>

          <!-- Birth day -->
          <ion-row>
            <ion-col>
              <ion-input
                mode="md"
                :class="{
                  'ion-touched': birthdate.length > 0,
                  'ion-invalid': !isOver18(),
                  'ion-valid has-focus': isOver18()
                }"
                :error-text="t('SignUpPage.under18')"
                fill="solid"
                ref="birthdateInput"
                type="date"
                :label="t('SignUpPage.birthdate') + ' *'"
                label-placement="floating"
                v-model="birthdate"
                @ionFocus="handleFocus"
                @ionBlur="handleBlur"
              ></ion-input>
            </ion-col>
          </ion-row>

          <!-- phone select -->
          <ion-row>
            <ion-col size="4">
              <ion-select
                mode="ios"
                :class="{
                  'ion-touched': mobileNumber.length > 0,
                  'ion-invalid': !validatePhoneNumber(),
                  'ion-valid has-focus': validatePhoneNumber()
                }"
                interface="action-sheet"
                fill="solid"
                v-model="selectedCountry"
                class="country-select"
              >
                <ion-select-option
                  v-for="country in countries"
                  :key="country.code"
                  :value="country.extension"
                >
                  {{ country.flag }} {{ country.extension }}
                </ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col size="8">
              <ion-input
                mode="md"
                :class="{
                  'ion-touched': mobileNumber.length > 0,
                  'ion-invalid': !validatePhoneNumber(),
                  'ion-valid has-focus': validatePhoneNumber()
                }"
                fill="solid"
                type="tel"
                :label="t('SignUpPage.mobileNumber') + ' *'"
                label-placement="floating"
                inputmode="numeric"
                v-model="mobileNumber"
                @ionInput="validatePhoneNumber"
                @ionFocus="handleFocus"
              ></ion-input>
            </ion-col>
          </ion-row>

          <!-- Errors -->
          <ion-row>
            <ion-col>
              <ion-text color="danger" v-if="errorMessages">{{
                errorMessages
              }}</ion-text>
            </ion-col>
          </ion-row>

          <!-- terms and conditions -->
          <ion-row class="checkbox-row">
            <ion-col size="12">
              <div class="checkbox-container">
                <ion-checkbox
                  aria-required="true"
                  v-model="acceptTerms"
                ></ion-checkbox>
                <ion-label>{{ t('SignUpPage.acceptTerms') + ' *' }} </ion-label>
              </div>
            </ion-col>
          </ion-row>

          <!-- Agree to recieve promotional messages -->
          <ion-row class="checkbox-row">
            <ion-col size="12">
              <div class="checkbox-container">
                <ion-checkbox v-model="acceptPromotions"></ion-checkbox>
                <ion-label>{{ t('SignUpPage.acceptPromotions') }}</ion-label>
              </div>
            </ion-col>
          </ion-row>

          <!-- Continue button -->
          <ion-row>
            <ion-col>
              <ion-button
                :disabled="!canSubmit()"
                class="ion-margin-top"
                color="primary"
                shape="round"
                size="default"
                expand="block"
                type="submit"
                >{{ t('SignUpPage.continue') }}</ion-button
              >
            </ion-col>
          </ion-row>

          <!-- Login redirect -->
          <ion-row class="ion-justify-content-center ion-align-items-center">
            <ion-text>
              <p style="text-align: center">
                {{ t('SignUpPage.alreadyHaveAccount') }}
                <RouterLink to="/login">
                  <b>{{ t('SignUpPage.loginnow') }}</b>
                </RouterLink>
              </p>
            </ion-text>
          </ion-row>
        </form>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style lang="scss">
ion-input.ion-touched {
  --border-color: var(--highlight-color) !important;
  .label-text.sc-ion-input-md {
    color: var(--highlight-color) !important;
  }
}

.full-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.keyboard-open.button-container {
  margin-top: 5px;
  /* Ajusta este valor según sea necesario */
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container ion-checkbox {
  margin-right: 8px;
}

.checkbox-row {
  margin-top: 10px;
}

.required {
  color: var(--ion-text-color-step-600);
  font-size: 0.75rem;
}
</style>
