<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import HeaderHome from '../../components/Header.vue';
import {
  IonPage,
  IonContent,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonButton
} from '@ionic/vue';
import { ref } from 'vue';

const router = useRouter();
const { t } = useI18n();
const selectedSegment = ref('showQr');

const login = () => {
  router.push('/loginView');
};
</script>

<template>
  <ion-page>
    <HeaderHome :showBackButton="true" />
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-segment v-model="selectedSegment">
          <ion-segment-button value="newtag">
            <ion-label>{{ t('showQr.newtag') }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="activeId ">
            <ion-label>{{ t('showQr.activeId') }}</ion-label>
          </ion-segment-button>
        </ion-segment>
        <ion-row
          class="ion-justify-content-center ion-align-items-center ion-margin-vertical"
          style="height: 40vh"
        >
          <ion-col size="12" class="ion-text-center">
            <ion-card-title style="font-size: 2rem"
              >AQUI VA IR EL QR</ion-card-title
            >
          </ion-col>
        </ion-row>

        <div v-if="selectedSegment === 'newtag'" style="margin-top: 25vh">
          Aqui va ir el contenido de la pestaña "Nuevo Tag"
        </div>

        <div
          v-else-if="selectedSegment === 'activeId'"
          style="margin-top: 25vh"
        >
          Aqui va ir el contenido de la pestaña "ID Activo"
        </div>

        <div style="margin-top: 25vh">
          <ion-button
            class="ion-margin-vertical"
            color="primary"
            shape="round"
            expand="block"
            @click="login"
            >{{ t('showQr.showQr') }}</ion-button
          >
        </div>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style scoped>
/* No se necesitan estilos adicionales si usas las utilidades de Ionic */
</style>
