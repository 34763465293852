import { NavigationGuard, RouteRecordRedirectOption } from 'vue-router';
import { sessionOrFalse } from './common';

export const redirectToPortalOrLanding: RouteRecordRedirectOption = (_) => {
  const session = sessionOrFalse();
  let redirectPath: string;
  if (!session) {
    redirectPath = '/landing';
  } else if (session.verified) {
    redirectPath = '/portal';
  } else {
    redirectPath = '/otp';
  }
  return { path: redirectPath };
};
