import router from '..';
import { NavigationGuard } from 'vue-router';
import { logout, SessionState } from '../sessionHandler';
import {
  sessionOrFalse,
  isExpiredSession,
  isUnverifiedSession
} from './common';

/**
 * Redirects the user to the home page if there is no active session.
 * This function acts as a guard to ensure that only guests (non-authenticated users) can access certain routes.
 *
 * @remarks
 * This function uses the `getSession` function to check for an active session.
 * If no session is found, it redirects the user to the home page using the `router.push` method.
 *
 * @returns {void} This function does not return any value.
 */
export const mustBeLoggedInUserGuard: NavigationGuard = (to, from, next) => {
  if (!sessionOrFalse()) {
    next('/');
    return;
  }
  next();
};

/**
 * Guard function to check if the session's JWT is unexpired.
 * If the JWT is missing or expired, the user is logged out and redirected to the home page.
 *
 * @param {SessionState} session - The current session state containing the JWT.
 * @returns {void}
 */
export const mustBeUnexpiredSessionGuard: NavigationGuard = (
  to,
  from,
  next
) => {
  const session = sessionOrFalse();
  // Check that session exists
  if (!session) {
    logout();
    next('/');
    return;
  }

  // Check if session is not expired
  if (isExpiredSession(session)) {
    logout();
    next('/');
    return;
  }
  next();
};

/**
 * Guard function to handle unverified sessions.
 *
 * If the session's verification time has expired, it logs out the user and redirects them to the home page.
 *
 * @param {SessionState} session - The current session state.
 * @returns {void}
 */
export const mustBeVerifiedSessionGuard: NavigationGuard = (to, from, next) => {
  const session = sessionOrFalse();

  // Check that session exists
  if (!session) {
    console.log('No session found, logging out');
    logout();
    router.push('/');
    return;
  }

  // Check if session is not expired
  if (isExpiredSession(session)) {
    console.log('Session expired, logging out');
    logout();
    next('/');
    return;
  }

  // Check if session is verified
  if (isUnverifiedSession(session)) {
    console.log('Session not verified, logging out');
    logout();
    next('/');
    return;
  }

  next();
};

export const mustBeUnverifiedSessionGuard: NavigationGuard = (
  to,
  from,
  next
) => {
  const session = sessionOrFalse();

  // Check that session exists
  if (!session) {
    console.log('No session found, logging out');
    router.push('/');
    return;
  }

  // Check if session is not expired
  if (isExpiredSession(session)) {
    console.log('Session expired, logging out');
    logout();
    next('/');
    return;
  }

  // Check if session is verified
  if (!isUnverifiedSession(session)) {
    console.log('Session verified, redirecting to home');
    next('/');
    return;
  }

  next();
};

export const mustNotHaveSessionGuard: NavigationGuard = (to, from, next) => {
  if (sessionOrFalse()) {
    next('/otp');
    return;
  }
  next();
};
