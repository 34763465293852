import { usersClient } from '@/router/axiosClient';
import { getSession } from '@/router/sessionHandler';
import { IUserDto } from '@future-planet-for-monkeys/typescript-axios-client';
import { reactive } from 'vue';
export type CardPaymentMethod = {
  network: string;
  cardType: string;
  maskedNumbers: string;
  expiry: string;
  paymentMethodId: string;
};

export type MoniiWallet = {
  points: number;
  currency: string;
};

type GlobalUserState =
  | (IUserDto & { card?: CardPaymentMethod; wallet: MoniiWallet })
  | null;

/**
 * Retrieves real-time user data based on the current session.
 *
 * @returns {Promise<IUserDto | null>} A promise that resolves to the user data if successful, or null if there is no session or an error occurs.
 */
async function getUserData(): Promise<GlobalUserState> {
  const session = getSession();
  if (!session || !session.user?.id) {
    return null;
  }
  const response = await usersClient.getMe();
  const { success: user, failure } = response.data as any;
  let cardPaymentMethod: CardPaymentMethod | undefined = undefined;
  let moniiWallet: MoniiWallet | undefined = undefined;
  if (user?.paymentMethods) {
    const paymentMethod = user.paymentMethods.find((pm: any) => pm.id);
    const wallet = user.paymentMethods.find((pm: any) => pm.type === 'Wallet')!;
    if (paymentMethod) {
      const card = paymentMethod.card;
      cardPaymentMethod = {
        paymentMethodId: paymentMethod.id,
        network: card.brand,
        cardType: card.funding,
        maskedNumbers: `**** **** **** ${card.last4}`,
        expiry: `${card.exp_month}/${card.exp_year}`
      };
    }

    moniiWallet = {
      points: wallet.atomicUnits,
      currency: wallet.currencyCode
    };
  }
  if (user) {
    return {
      ...user,
      card: cardPaymentMethod,
      wallet: moniiWallet
    };
  } else {
    console.error(failure);
    return null;
  }
}

export async function updateUserState() {
  const user = await getUserData();
  userState.user = user;
}

export const userState = reactive<{
  user: GlobalUserState;
}>({
  user: null
});
